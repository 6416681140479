import { fadeIn, fadeOut } from '@/helpers/jqry-replace';

window.ModalAW = class ModalAW {
    constructor(content = '') {
        this.container = document.querySelector('[data-modal-aw]');

        if (!this.container) {
            return;
        }

        this.content_containers = this.container.querySelectorAll('[data-modal-aw-content]');
        this.content_main_container = this.container.querySelector('[data-modal-aw-content-main]');

        if (!this.content_main_container) {
            return;
        }

        this.content = content;

        this.content_main_container.innerHTML = this.content;

        this.initClose();
    }

    open(contentSelector = '') {
        document.body.style.overflowY = 'hidden';

        if (contentSelector !== '') {
            const contentNode = this.container.querySelector(contentSelector);
            if (contentNode) {
                this.content_containers.forEach((el) =>
                    el.classList.add('hide'));
                contentNode.classList.remove('hide');
            }
        } else {
            this.content_containers.forEach((el) => {
                if (!el.hasAttribute('data-modal-aw-content-main')) {
                    el.classList.add('hide');
                }
            });
            this.content_main_container.classList.remove('hide');
        }

        fadeIn(this.container);
        this.container.classList.add('open');

        const e = new Event('modalAW:open');
        document.dispatchEvent(e);
    }

    close() {
        document.body.style.overflowY = 'auto';
        fadeOut(this.container);
        this.container.classList.remove('open');

        const e = new Event('modalAW:close');
        document.dispatchEvent(e);
    }

    initClose() {
        document.body.addEventListener('click', (e) => {
            if (
                !e.target.closest('[data-modal-aw-open]') &&
                !e.target.closest('[data-modal-aw-opener]') &&
                (!e.target.closest('[data-modal-aw-inner]') ||
                    e.target.closest('[data-modal-aw-close]'))
            ) {
                this.close();
            }
        });
    }
};
